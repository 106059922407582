var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      attrs: {
        app: "",
        clipped: "",
        "mobile-breakpoint": "600",
        color: "sidebarLight"
      }
    },
    [
      _c(
        "v-list-item",
        { attrs: { to: { name: "user-profile" } } },
        [
          _c("v-list-item-action", [_c("v-icon", [_vm._v("person")])], 1),
          _c("v-list-item-title", [_vm._v("Profile")])
        ],
        1
      ),
      _c(
        "v-list-item",
        { attrs: { to: { name: "user-ssh-keys" } } },
        [
          _c("v-list-item-action", [_c("v-icon", [_vm._v("mdi-key")])], 1),
          _c("v-list-item-title", [_vm._v("SSH Keys")])
        ],
        1
      ),
      _c(
        "v-list-item",
        { attrs: { to: { name: "user-snowflake-tokens" } } },
        [
          _c("v-list-item-action", [_c("v-icon", [_vm._v("mdi-database")])], 1),
          _c("v-list-item-title", [_vm._v("Table access")])
        ],
        1
      ),
      _c(
        "v-list-item",
        { attrs: { to: { name: "hpc-file-synchronisation" } } },
        [
          _c("v-list-item-action", [_c("v-icon", [_vm._v("device_hub")])], 1),
          _c("v-list-item-title", [_vm._v("HPC integration")])
        ],
        1
      ),
      _c(
        "v-list-item",
        { attrs: { to: { name: "user-tasks" } } },
        [
          _c("v-list-item-action", [_c("v-icon", [_vm._v("event_note")])], 1),
          _c("v-list-item-title", [_vm._v("User tasks")])
        ],
        1
      ),
      _c(
        "v-list-item",
        { attrs: { to: { name: "dropbox-link" } } },
        [
          _c("v-list-item-action", [_c("v-icon", [_vm._v("sync")])], 1),
          _c("v-list-item-title", [_vm._v("Dropbox sync")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }