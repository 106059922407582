<template>
    <v-navigation-drawer app clipped mobile-breakpoint="600" color="sidebarLight">
        <v-list-item :to="{ name: 'user-profile' }">
            <v-list-item-action>
                <v-icon>person</v-icon>
            </v-list-item-action>
            <v-list-item-title>Profile</v-list-item-title>
        </v-list-item>

        <v-list-item :to="{ name: 'user-ssh-keys' }">
            <v-list-item-action>
                <v-icon>mdi-key</v-icon>
            </v-list-item-action>
            <v-list-item-title>SSH Keys</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'user-snowflake-tokens' }">
            <v-list-item-action>
                <v-icon>mdi-database</v-icon>
            </v-list-item-action>
            <v-list-item-title>Table access</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'hpc-file-synchronisation' }">
            <v-list-item-action>
                <v-icon>device_hub</v-icon>
            </v-list-item-action>
            <v-list-item-title>HPC integration</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'user-tasks' }">
            <v-list-item-action>
                <v-icon>event_note</v-icon>
            </v-list-item-action>
            <v-list-item-title>User tasks</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'dropbox-link' }">
            <v-list-item-action>
                <v-icon>sync</v-icon>
            </v-list-item-action>
            <v-list-item-title>Dropbox sync</v-list-item-title>
        </v-list-item>
    </v-navigation-drawer>
</template>

<script>
export default {}
</script>
